<template>
    <div class="exchange-box">
        <div class="header bg-color">
            <div class="count-box">
                <div class="text">
                    <div class="headname">累计扫码兑换<span class="crl">{{count}}</span>次</div>
                    <!-- <div>共兑换：5</div> -->
                </div>
            </div>
            <div class="tabs-box">
                    <Tabs v-model="activeName" title-active-color="#000" v-on:click="clickMore()">
                        <tab  title="待寄出" name="a"></tab>
                        <tab  title="待收货" name="b"></tab>
                        <tab  title="已收取" name="c"></tab>
                        <tab  title="被拒绝" name="d"></tab>
                    </Tabs> 
             </div>
        </div>
        <div class="list-box">
            <div class="list" v-for="(item,index) in list" :key="index">
                <div class="list-block">
                    <div class="time">
                        <div class="left">{{item.add_time}}</div>
                        <div class="rigth" v-if="item.status==0">
                            待寄出
                        </div>
                        <div class="rigth" v-if="item.status==1">
                            已寄出
                        </div>
                        <div class="rigth" v-if="item.status==2">
                            已收取
                        </div>
                    </div>
                    <div class="activity"><span>活动名【ID:{{item.id}}】：</span>{{item.activityname}}</div>
                    <div class="info">
                        <div class="goodsinfo">
                            <div class="goodsimg">
                                <img :src="item.image"/>
                            </div>
                            <div class="goodsname">
                                <div class="Ellipses1">产品名：{{item.product_name}}</div>
                                <div class="tackinfo">
                                    <div>授权码：{{item.hexiao}}</div>
                                    <div style="text-align: center;background: #eee;">收货信息</div>
                                    <div class="name lefts">姓名：{{item.username}}</div>
                                    <div class="myphone lefts">号码：{{item.telphone}}</div> 
                                    <div class="dizhi lefts ">地址：{{item.city}} {{item.address}}</div>
                                </div>
                            </div>
                            <div class="goodscount">数量：1</div>   
                        </div>
                        <div class="activity" v-if="status == 1"><span>快递单号：</span>{{item.delivery_id}}</div>
                        <div class="bit-win" v-if="status >0">
                            <span class="tack red" v-if="status == 1" @click="setOrderStatus(item.id)">确认收货</span>
                            <!-- <span class="tack green" v-if="status == 0">修改地址</span> -->
                        </div>
                        <div class="bit-wins" v-else>
                            <span class="tack" v-if="status == -1">拒绝原因：{{item.refuse_res}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Loading" v-if="loading">
                    <Loading size="24px">加载中...</Loading>
                </div>
                <div class="Loading" v-if="finished">没有更多了~</div>
        </div>
    </div>
</template>
<script>
import { Icon, Toast, Tab, Tabs ,Loading } from "vant";
import dialog from "@utils/dialog";
import { getScanOrderList,getScanOrderListCount,getStatusUser } from "@api/tasting";
export default {
    name: "exchangeList",
        components: {
            Icon, Toast, Tab, Tabs ,Loading
        },
        data: function() {
            return {
                list:[],
                activeName:'a',
                loading:false,
                finished:false,
                status:0,
                page:1,
                limit:3,
                count:0,
            };
        },
        created() {
            
        },
        mounted: function() {
            this.getCount();
            this.getList();
            var that=this;
            window.addEventListener("scroll", function(event) {
                var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop) {
                    that.Loadmore();
                }
            });
        },
        methods: {
            setStatus:function(){
                switch(this.activeName){
                    case 'a':
                       this.status = 0; 
                       break;
                    case 'b':
                       this.status = 1; 
                       break;
                    case 'c':
                       this.status = 2; 
                       break;
                    case 'd':
                       this.status = -1; 
                       break;
                    default:
                        this.status = 0; 
                       break;
                }
            },
            setReset:function(){
                this.list = [];
                this.page=1;
                this.limit=3;
                this.loading=false;
                this.finished=false;
            },
            goPages: function(url) {
                this.$router.push({ path: url });
            },
            Loadmore: function() {
                if(this.loading) return;
                if(this.finished) return;
                this.getList();
            },
            clickMore:function(){
                this.setStatus();
                this.setReset();
                this.getList();
            },
            getCount:function(){
                getScanOrderListCount({
                }).then(res => {
                    this.count = res.data.count;
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            setOrderStatus:function(id){
                getStatusUser({
                    id:id,
                }).then(res => {
                    dialog.success("收货成功");
                    this.clickMore();
                })
                .catch(err => {
                    dialog.error("收货失败");
                });
            },
            getList:function(){
                this.setStatus();
                var _status = this.status;
                this.loading = true;
                getScanOrderList({
                    page: this.page,
                    limit: this.limit,
                    status: _status,
                }).then(res => {
                    var listdata = res.data.list;
                    listdata.forEach((item)=>{
                        this.list.push(item);
                    })
                    this.loading = false;
                    this.page++;
                    if(listdata.length<3){
                        this.finished = true;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            }
        }
            
}
</script>
<style lang="scss">
.Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;}
::v-deep{
    .exchange-box{
        .tabs-box{  
            .van-tabs__line{
                transform: translateX(57.5px) translateX(-50%);
            }
        }
    } 
}
::v-deep{
    .tabs-box{  
        .van-tabs__wrap{   height: 1rem;}
    }
}
    .exchange-box{
        .header{
            height: 3rem;
            padding: 0 0.3rem; 
            .count-box{
                height: 2.5rem;
                align-items: center;
                justify-content: space-between;
                display: flex;
                flex-wrap: wrap;
                .text{
                    color: rgba(255, 255, 255, 0.856);
                    .headname{
                        font-size: 0.4rem;
                        font-weight: bold;
                        color: #fff;
                        margin-bottom: 0.1rem;
                        .crl{
                            font-size: 0.8rem;
                        }
                    }
                }
            }   
            .tabs-box{
                background: #fff;
                width: 100%;
                height: 1rem;
                border-radius: 0.1rem;
            }        
        }
        .bg-color{
            background-color: #e93323!important;
        }
        .list-box{
            margin-top: 0.8rem;
            .list{
             .list-block{
                  margin:0.3rem 0.3rem 0 0.3rem;
                  background: #fff;
                  border-radius: 0.1rem;
                  .time{
                    border-bottom: 1px solid #eee;  
                    padding: 0.2rem;
                    display: flex;
                    align-items: center;
                    .left{
                        width: 85%;
                    }
                    .rigth{
                        color: #e93323;
                        text-align: center;
                        width: 15%;
                    }
                  }
                  .activity{
                        padding: 0.2rem 0.2rem 0 0.2rem ;
                        color: red;
                        font-size: 0.3rem;
                        span{
                            color: #000;
                        }
                    }
                  .info{
                      .goodsinfo{
                          display: flex;
                          padding: 0.2rem;
                            .goodsimg{
                                    width: 1.5rem;
                                    height: 1.5rem;
                                    border-radius: 0.5rem;
                                    margin-right:0.2rem;
                                    img{
                                        width: 100%;
                                    } 
                            }   
                            .goodsname{
                                width: 55%;
                                .tackinfo{
                                    .lefts{
                                        //margin-left: 0.2rem;
                                        color:#ccc;
                                        font-size: 0.25rem;
                                    }
                                    
                                }
                            }
                            .goodscount{
                                width: 20%;
                                text-align: right;
                                color: #999;
                            }
                      }
                      .bit-win{
                          padding: 0.3rem 0.2rem;
                          border-top: 1px solid #eee; 
                          text-align: right;
                          .red{
                              padding: 0.1rem 0.2rem;
                              color: #fff;
                              background: red;
                              border-radius: 1rem;
                          } 
                          .green{
                              padding: 0.1rem 0.2rem;
                              color: #fff;
                              background: green;
                              border-radius: 1rem;
                          } 
                          
                      }
                    .bit-wins{
                        padding: 0.3rem 0.2rem;
                        border-top: 1px solid #eee; 
                        text-align: left;
                        color: red;
                    }   
                  }
                }
            }
        }

    }
</style>